import { Link } from 'gatsby';
import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { HomeBannerMoreSvg } from '../../../static/svg/invoiceSvgCom';

const HomeBanner = () => {
  return (
    <div
      className="banner banner-style-5 banner-home"
      style={{
        backgroundImage: "url('/images/home/banner-bg.jpg')",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-xl-8">
            <div className="banner-content">
              <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                <h1 className="title">Financing for growing enterprises in Asia</h1>
                <span className="subtitle">
                  <strong>banco</strong> enables your business to{' '}
                  <strong>strengthen cash flow</strong> and <strong>improve trade relations</strong>{' '}
                  with your supply chain
                </span>
                <div>
                  <Link to="/contact" className="banco-btn btn-fill-primary">
                    Speak with our team
                  </Link>
                </div>
                <div className="home-banner-more">
                  <HomeBannerMoreSvg />
                </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
