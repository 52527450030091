import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import moment from 'moment';
import React from 'react';

type MediaMentionItemProps = {
  data: {
    id: string;
    Title: string;
    Date: string;
    Cover?: any;
    Slug: string;
  };
  mediaStyle?: string;
};

const MediaMentionItem = ({ data, mediaStyle }: MediaMentionItemProps) => (
  <div className={`media-grid ${mediaStyle ?? ''}`}>
    <div className="thumbnail">
      <Link to={`/media-mention/${data.Slug}`}>
        <GatsbyImage
          image={data.Cover?.localFile?.childImageSharp?.gatsbyImageData}
          alt={data.Title}
        />
      </Link>
    </div>
    <div className="content">
      <div className="info">
        <ul className="media-meta list-unstyled">
          <li>{moment(data.Date).format('MMMM YYYY')}</li>
        </ul>
      </div>
      <h3 className="title">
        <Link to={`/media-mention/${data.Slug}`} title={data.Title}>
          {data.Title}
        </Link>
      </h3>
    </div>
    <Link className="banco-btn btn-borderd" to={`/media-mention/${data.Slug}`}>
      Read more
    </Link>
  </div>
);

export default MediaMentionItem;
