import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import {
  FastSimpleSvg,
  MarketSvg,
  NoHiddenFeesSvg,
  OnePlatformSvg,
  R3Svg,
  SavillsSvg,
  SbiSvg,
} from '../../static/svg/companySvgCom';
import HomeBanner from '../components/banner/HomeBanner';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import Layout from '../components/layout';
import MediaMentionItem from '../components/media-mention/MediaMentionItem';
import Seo from '../components/seo';
import SectionTitle from '../elements/SectionTitle';

const IndexPage = () => {
  const { allStrapiMediaMention } = useStaticQuery(graphql`
    query {
      allStrapiMediaMention(limit: 4, sort: { fields: Date, order: DESC }) {
        edges {
          node {
            id
            Slug
            Cover {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 500, height: 325)
                }
              }
            }
            Date
            Title
          }
        }
      }
    }
  `);
  const [activeId, setActiveId] = useState(0);

  const handleChange = (newActiveId: any) => {
    setActiveId(newActiveId);
  };
  return (
    <Layout>
      <Seo />
      <main className="main-wrapper">
        <Header />
        <HomeBanner />
        <div className="section section-padding section-home">
          <div className="container">
            <p className="icon-title">Trusted by reputed brands</p>
            <div className="mobile-icon-wrap home-mobile">
              <div className="row icon-item">
                <div className="col">{SbiSvg({})}</div>
                <div className="col">{MarketSvg({})}</div>
              </div>
              <div className="row icon-item">
                <div className="col">{SavillsSvg({})}</div>
                <div className="col">{R3Svg({})}</div>
                {/* <div className="col">{CoboSvgSmall({})}</div> */}
              </div>
            </div>
            <div className="row icon-wrap">
              <div className="col">{SbiSvg({})}</div>
              <div className="col">{MarketSvg({})}</div>
              <div className="col">{SavillsSvg({})}</div>
              <div className="col">{R3Svg({})}</div>
            </div>

            <p className="-breadcrumb">One platform to address all your financing needs</p>
            <div className="row content-2">
              <div className="card col-4">
                <div>{OnePlatformSvg({})}</div>
                <div>
                  <p className="-title">One Platform</p>
                  <p className="-description">
                    Stay on top of your receivables with one single platform. Scale your business
                    with added convenience.
                  </p>
                </div>
              </div>
              <div className="card col-4">
                <div>{FastSimpleSvg({})}</div>
                <div>
                  <p className="-title">Fast and simple</p>
                  <p className="-description">Apply easily online to get in touch with us.</p>
                </div>
              </div>
              <div className="card col-4">
                <div>{NoHiddenFeesSvg({})}</div>
                <div>
                  <p className="-title">No hidden fees</p>
                  <p className="-description">
                    A fixed convenience fee when you use the service. Better cashflow management
                    with predictability.
                  </p>
                </div>
              </div>
            </div>

            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link${activeId === 0 ? ' active' : ''}`}
                  type="button"
                  role="tab"
                  aria-selected="true"
                  onClick={() => handleChange(0)}
                >
                  SMEs
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link${activeId === 1 ? ' active' : ''}`}
                  type="button"
                  role="tab"
                  aria-selected="true"
                  onClick={() => handleChange(1)}
                >
                  Enterprises
                </button>
              </li>
            </ul>

            {activeId === 0 ? (
              <div className="row content-3 pb--70">
                <div className="card col-4">
                  <img
                    className="light-mode"
                    style={{ borderTopLeftRadius: 19, borderTopRightRadius: 19 }}
                    src="/images/home-img-1.png"
                  />
                  <div>
                    <img
                      className="light-mode"
                      src="/images/invoicepaid-img.png"
                      style={{ width: 115 }}
                    />
                    <p className="-description">
                      Collect your monthly invoice on time, every time. Instant Approval. Same day
                      disbursement. Flat Fee. Minimal Documents.
                    </p>
                    <div className="btn-wrap">
                      <Link className="banco-btn btn-borderd" to="/invoice-paid">
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card col-4">
                  <img
                    className="light-mode"
                    style={{ borderTopLeftRadius: 19, borderTopRightRadius: 19 }}
                    src="/images/home-img-3.png"
                  />
                  <div>
                    <img
                      className="light-mode"
                      src="/images/equippaid-logo.png"
                      style={{ width: 108 }}
                    />
                    <p className="-description">
                      Get 100% financing of your equipment with no downpayment. Conserve cashflow
                      with fixed monthly payments. Choose EquipPaid+, your choice equipment
                      financing leasing for SMEs in Singapore
                    </p>
                    <div className="btn-wrap">
                      <Link className="banco-btn btn-borderd" to="/equip-paid">
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card col-4">
                  <img
                    className="light-mode"
                    style={{ borderTopLeftRadius: 19, borderTopRightRadius: 19 }}
                    src="/images/home-img-2.png"
                  />
                  <div>
                    <p className="-title">Accounts Payables / Receivables Automation</p>
                    <p className="-description">
                      Reduce manual work and time spent on exceptional handling. Free up your team
                      to focus on growing your business. Leave the receivables, payables to our
                      banco AP/AR automation tool
                    </p>
                    <div className="btn-wrap">
                      <Link className="banco-btn btn-borderd" to="/ap-ar">
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row content-3 pb--70">
                <div className="card col-4">
                  <img className="light-mode" src="/images/home-img-4.png" />
                  <div>
                    <p className="-title">Deep-Tier Supply Chain Finance</p>
                    <p className="-description">
                      Unlock cash flow with confidence using Banco innovative solution. Strengthen
                      your supply chain with early financing program. Improve your business
                      resilience and competitiveness.
                    </p>
                    <div className="btn-wrap">
                      <Link className="banco-btn btn-borderd" to="/deep-tier-scf">
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card col-4">
                  <img className="light-mode" src="/images/home-img-5.png" />
                  <div>
                    <p className="-title">Green Finance</p>
                    <p className="-description">
                      Achieve favourable green financing rate by incorporating supply chain finance
                      with sustainability assessment. Cascade your green efforts by incentivising
                      suppliers to adopt more sustainable activities.
                    </p>
                    <div className="btn-wrap">
                      <Link className="banco-btn btn-borderd" to="/green-finance">
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card col-4">
                  <img className="light-mode" src="/images/home-img-2.png" />
                  <div>
                    <p className="-title">Accounts Payables / Receivables Automation</p>
                    <p className="-description">
                      Reduce manual work and time spent on exceptional handling. Free up your team
                      to focus on growing your business. Leave the receivables, payables to our
                      banco AP/AR automation tool.
                    </p>
                    <div className="btn-wrap">
                      <Link className="banco-btn btn-borderd" to="/ap-ar">
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <SectionTitle title="Media mentions" />
            <div className="row row-8">
              {allStrapiMediaMention.edges.map((data: { node: any }) => (
                <div className="col-xl-3 col-lg-4 col-md-6 media-item" key={data.node.id}>
                  <MediaMentionItem data={data.node} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </Layout>
  );
};

export default IndexPage;
